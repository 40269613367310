import { NgModule } from '@angular/core';
import { ButtonLinkComponent } from './button-link.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
@NgModule({
	imports: [RouterModule, CommonModule],
	exports: [ButtonLinkComponent],
	declarations: [ButtonLinkComponent],
	providers: []
})
export class ButtonLinkModule {}
