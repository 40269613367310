<div class="container__register">
	<div class="container__register__close">
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			(click)="closeModal()"
		>
			<path
				d="M1 11L11 1M1 1L11 11"
				stroke="#171717"
				stroke-width="1.67"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</div>
	<div class="container__register__tabs" *ngIf="!isFinish && !showSectionForget">
		<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
			<li [ngbNavItem]="1">
				<a ngbNavLink>Datos de acceso</a>
				<ng-template ngbNavContent>
					<div class="container__register__tabs__form">
						<app-title [text]="'Regístrate'" [line]="true"></app-title>
						<form [formGroup]="formGroup" autocomplete="off">
							<app-input
								[label]="'Correo electrónico'"
								[placeholder]="'Correo electrónico'"
								[icon]="'sms.svg'"
								[type]="'email'"
								[name]="'CorreoElectronico'"
								[class]="emailField.touched && emailField!.invalid ? 'error' : ''"
								(focusEmail)="isValidEmail = true"
								[maxlength]="50"
								[labelError]="
									emailField.touched && (emailField!.hasError('required') || emailField!.hasError('pattern'))
										? 'Correo inválido.'
										: !isValidEmail
										? 'Este correo ya está registrado'
										: emailField.touched && emailField!.hasError('whitespace')
										? 'Correo no válido'
										: ''
								"
							></app-input>
							<app-input
								[label]="'Contraseña'"
								[placeholder]="'Contraseña'"
								[password]="true"
								[name]="'Contrasenia'"
								[maxlength]="50"
								[class]="passwordField.touched && passwordField!.invalid ? 'error' : ''"
								[labelError]="
									passwordField.touched && passwordField!.hasError('hasSpecialCharacters')
										? 'No se admiten caracteres especiales'
										: ''
								"
							></app-input>

							<div class="messages__password">
								<span>La contraseña debe cumplir los siguientes criterios:</span>
								<div class="messages__password__item">
									<img
										[src]="
											!passwordField.hasError('required') && !passwordField.hasError('minlength')
												? 'assets/icons/Correct.svg'
												: passwordField.touched &&
												  (passwordField!.hasError('required') || passwordField!.hasError('minlength'))
												? 'assets/icons/incorrect-close.svg'
												: 'assets/icons/tick-circle.svg '
										"
									/>
									<span>Tener un mínimo 8 caracteres.</span>
								</div>
								<div class="messages__password__item success">
									<img
										[src]="
											!passwordField.hasError('required') && !passwordField.hasError('hasNumber')
												? 'assets/icons/Correct.svg'
												: passwordField!.touched &&
												  (passwordField!.hasError('required') || passwordField!.hasError('hasNumber'))
												? 'assets/icons/incorrect-close.svg'
												: 'assets/icons/tick-circle.svg'
										"
									/>
									<span>Incluir al menos un número.</span>
								</div>
								<div class="messages__password__item">
									<img
										[src]="
											!passwordField.hasError('required') && !passwordField.hasError('hasCapitalCase')
												? 'assets/icons/Correct.svg'
												: passwordField!.touched &&
												  (passwordField!.hasError('required') || passwordField!.hasError('hasCapitalCase'))
												? 'assets/icons/incorrect-close.svg'
												: 'assets/icons/tick-circle.svg'
										"
									/>
									<span>Incluir al menos una mayúscula.</span>
								</div>
							</div>

							<div class="container__checkbox">
								<app-input-check
									[label]="'He leído y acepto las'"
									[name]="'TerminosCondiciones'"
									[textLink]="'Políticas de privacidad de datos'"
									[url]="'https://jockeyplaza.com.pe/informacion/politica-privacidad.html'"
									[labelError]="
										acceptTermsField.touched && acceptTermsField!.invalid
											? 'Debe aceptar esta opción para continuar con el registro'
											: ''
									"
								></app-input-check>
							</div>

							<div class="container__checkbox">
								<app-input-check
									[label]="'He leído y acepto las'"
									[name]="'PoliticasRedes'"
									[textLink]="'Políticas de privacidad de redes sociales'"
									[url]="'https://jockeyplaza.com.pe/informacion/politica-privacidad-redes-sociales.html'"
									[labelError]="isCheckFacebook ? 'Debe aceptar esta opción para continuar con Facebook' : ''"
									(click)="changeCheckFacebook()"
								></app-input-check>
							</div>

							<div class="container__buttons">
								<app-button
									[text]="'Siguiente'"
									[color]="'primary'"
									[size]="'lg'"
									[classes]="'w-100'"
									(click)="registerStepOne()"
									[disabled]="isProcess"
									[random]="true"
								></app-button>
								<!--<app-button
									[text]="getScreenWidth > 291 ? 'Registrarte con Facebook' : 'Continuar con Facebook'"
									[color]="'facebook'"
									[size]="'lg'"
									[srcImg]="'button_facebook.svg'"
									[left]="true"
									[classes]="'w-100'"
									[disabled]="isProcessFacebook"
									(click)="registerFacebook()"
								></app-button>-->
							</div>

							<div class="container__link">
								<span class="text-account">¿Ya tienes una cuenta? </span>
								<span (click)="showLoginSection()" class="link">Ingresa aquí</span>
							</div>
						</form>
					</div>
				</ng-template>
			</li>
			<li [ngbNavItem]="2">
				<a ngbNavLink [class.disabled]="disabledTab2 || !formGroup.valid || emailVerify !== emailField.value"
					>Datos Personales</a
				>
				<ng-template ngbNavContent>
					<div class="container__register__tabs__form datos">
						<app-title [text]="'Regístrate'" [line]="true"></app-title>
						<form [formGroup]="formGroupDatos" autocomplete="off">
							<div class="row gy-4">
								<div class="col-md-6">
									<app-input
										[label]="'Nombres'"
										[placeholder]="'Nombres'"
										[type]="'text'"
										[name]="'Nombre'"
										[isOnlyText]="true"
										[maxlength]="50"
										[class]="nameField.touched && nameField!.invalid ? 'error' : ''"
										[labelError]="
											nameField.touched && nameField!.hasError('required')
												? 'Nombres es obligatorio'
												: nameField.touched && nameField!.hasError('minlength')
												? 'Debe tener mín. 2 caracteres'
												: nameField.touched && nameField!.hasError('whitespace')
												? 'Nombres no válido'
												: ''
										"
									></app-input>
								</div>
								<div class="col-md-6">
									<app-input
										[label]="'Apellidos'"
										[placeholder]="'Apellidos'"
										[type]="'text'"
										[name]="'Apellido'"
										[isOnlyText]="true"
										[maxlength]="100"
										[class]="lastNameField.touched && lastNameField!.invalid ? 'error' : ''"
										[labelError]="
											lastNameField.touched && lastNameField!.hasError('required')
												? 'Apellidos es obligatorio'
												: lastNameField.touched && lastNameField!.hasError('minlength')
												? 'Debe tener mín. 2 caracteres'
												: lastNameField.touched && lastNameField!.hasError('whitespace')
												? 'Apellidos no válido'
												: ''
										"
									></app-input>
								</div>
							</div>

							<div class="row gy-4">
								<div class="col-md-6">
									<app-input-date
										#childBirthdate
										[label]="'Fecha de Nacimiento'"
										[placeholder]="'Fecha de Nacimiento'"
										[dateDefault]="birthdate"
										(outFocus)="changeBirthdate($event)"
										[inputClass]="'modal-date'"
									></app-input-date>
								</div>
								<div class="col-md-6">
									<app-select
										[listItems]="listGenero"
										[label]="'Género'"
										(clickItem)="changeSelectGender($event)"
										[fullWidth]="true"
										[default]="genderField.value"
										[isError]="genderField.touched && genderField.value === null ? true : false"
									></app-select>
								</div>
							</div>

							<div class="row">
								<div class="col-md-12">
									<app-input-phone
										#childPhone
										(outFocus)="changePhone($event)"
										[dialCode]="dialField.value"
										[numberPhone]="phoneField.value"
									></app-input-phone>
								</div>
							</div>

							<div class="row">
								<div class="col-md-12">
									<app-input-document
										#childDocument
										(outFocus)="changeDocument($event)"
										[numberDocument]="numberDocumentField.value"
										[default]="typeDocumentField.value"
									></app-input-document>
								</div>
							</div>

							<div class="container__buttons">
								<app-button
									[text]="'Siguiente'"
									[color]="'primary'"
									[size]="'lg'"
									[classes]="'w-100'"
									(click)="registerStepTwo()"
									[random]="true"
								></app-button>
								<app-button
									[text]="'Anterior'"
									[color]="'outline-primary'"
									[size]="'lg'"
									[classes]="'w-100'"
									[random]="true"
									(click)="active = 1"
								></app-button>
							</div>

							<div class="container__link">
								<span class="text-account">¿Ya tienes una cuenta? </span>
								<span (click)="showLoginSection()" class="link">Ingresa aquí</span>
							</div>
						</form>
					</div>
				</ng-template>
			</li>
			<li [ngbNavItem]="3">
				<a
					ngbNavLink
					[class.disabled]="
						disabledTab3 ||
						!formGroupDatos.valid ||
						!formGroup.valid ||
						emailVerify !== emailField.value ||
						!isValidDocument ||
						!isValidDocument
					"
					>Intereses</a
				>
				<ng-template ngbNavContent>
					<div class="container__register__tabs__form">
						<app-title [text]="'Selecciona tus intereses'" [line]="true"></app-title>
						<div
							class="container__register__tabs__form__intereses"
							(scroll)="onScroll($event)"
							[ngStyle]="{
								'-webkit-mask-image': isEndScroll ? 'none' : 'linear-gradient(to top, transparent 4%, black 40%)'
							}"
						>
							<app-tag
								*ngFor="let item of listIntereses"
								[text]="item.nombre"
								(click)="checkInteres(item.id)"
								[active]="item.selected"
							></app-tag>
						</div>

						<div class="container__buttons intereses">
							<app-button
								[text]="'Crear cuenta'"
								[color]="'primary'"
								[size]="'lg'"
								[classes]="'w-100'"
								[disabled]="isProcess"
								[random]="true"
								(click)="executeRecaptchaV3(false)"
							></app-button>
							<app-button
								[text]="'Omitir por ahora'"
								[color]="'outline-primary'"
								[size]="'lg'"
								[classes]="'w-100'"
								[random]="true"
								(click)="executeRecaptchaV3(true)"
							></app-button>
						</div>
					</div>
				</ng-template>
			</li>
		</ul>

		<div [ngbNavOutlet]="nav" class="mt-2"></div>
	</div>

	<div class="container__finish" *ngIf="isFinish && !showSectionForget">
		<app-title [text]="'¡Te damos la bienvenida!'" [line]="true"></app-title>
		<span
			>Gracias por crear una cuenta en el Jockey Plaza. Ahora podrás agregar las tiendas y restaurantes que más te
			gusten a tus favoritos, añadir tus eventos al calendario, recibir notificaciones sobre los últimos descuentos ¡y
			mucho más!
		</span>
		<span class="resalt">Recuerda verificar tu cuenta para iniciar sesión</span>
		<section>
			<app-button
				[text]="'Finalizar'"
				[color]="'primary'"
				[size]="'lg'"
				[classes]="'w-100'"
				(click)="showLoginSection()"
			></app-button>
		</section>
	</div>

	<div class="container__recover" *ngIf="!isFinish && showSectionForget">
		<app-title [text]="'Restablecer contraseña'" [line]="true"></app-title>
		<span *ngIf="!isEmailNotRegister && !isEmailDelivered"
			>Ingresa el correo electrónico con el que te registraste para restablecer tu contraseña.</span
		>
		<span *ngIf="isEmailNotRegister"
			>No encontramos el correo ingresado. Si crees que se trata de un error, inténtalo nuevamente.</span
		>
		<span *ngIf="isEmailDelivered"
			>¡Hurra! Te hemos enviado un correo electrónico con los pasos a seguir para restablecer tu contraseña.</span
		>
		<form [formGroup]="formGroupRecover" autocomplete="off" *ngIf="!isEmailDelivered">
			<app-input
				[label]="'Correo electrónico'"
				[placeholder]="'Correo electrónico'"
				[icon]="'sms.svg'"
				[type]="'email'"
				[name]="'emailRecover'"
				[maxlength]="50"
				[class]="emailRecoverField.touched && emailRecoverField!.invalid ? 'error' : ''"
				[labelError]="
					emailRecoverField.touched &&
					(emailRecoverField!.hasError('required') || emailRecoverField!.hasError('pattern'))
						? 'Correo inválido.'
						: isEmailNotRegister
						? '¡Ups! El correo electrónico no es correcto.'
						: ''
				"
			></app-input>
		</form>
		<div class="container__recover__buttons">
			<ng-container *ngIf="!isEmailDelivered">
				<app-button
					[text]="'Enviar'"
					[color]="'primary'"
					[size]="'lg'"
					[classes]="'w-100'"
					(click)="sendRecoverPassword()"
					[disabled]="isProcess"
					[random]="true"
				></app-button>
				<app-button
					[text]="'Regresar'"
					[color]="'outline-primary'"
					[size]="'lg'"
					[classes]="'w-100'"
					(click)="goToLogin()"
					[random]="true"
				></app-button>
			</ng-container>
			<ng-container *ngIf="isEmailDelivered">
				<app-button
					[text]="'Ir al inicio'"
					[color]="'primary'"
					[size]="'lg'"
					[classes]="'w-100'"
					(click)="goToHome()"
				></app-button>
			</ng-container>
		</div>
	</div>
</div>
